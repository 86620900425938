import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

const date_fr = (date_str) => {
  let date_fr = "";
  if (date_str)
    date_fr = date_str.substring(8,10) + "/" + date_str.substring(5,7) + "/" + date_str.substring(0,4);

  return date_fr;
}


export const Objects = {
  clients: {
    inputs: [
      {
        id: "name",
        label: "Nom",
        type: "text",
      },
      {
        id: "email_address",
        label: "Adresse email",
        type: "text",
      },
      {
        id: "phone_number",
        label: "Numéro de téléphone",
        type: "mail",
      },
      {
        id: "access_code",
        label: "Code d'accès",
        type: "text",
      },
    ],
    columns: [
      {
        field: "name",
        headerName: "Nom",
        width: 230,
        renderCell: (params) => {
          return (params.row && params.row.name ? <strong>{params.row.name}</strong> : "");
        },
      },
      {
        field: "email_address",
        headerName: "Adresse email",
        width: 230,
      },
      {
        field: "phone_number",
        headerName: "Numéro de téléphone",
        width: 230,
      },
      {
        field: "access_code",
        headerName: "Code d'accès",
        width: 230,
      },
    ],
    labels: {
      listTitle: "Liste des clients",
      editTitle: "Modifier le client",
      addBtn: "Ajouter un client"
    }
  },
  orders: {
    inputs: [
      {
        id: "order_code",
        label: "Commande",
        type: "text",
      },
      { 
        id: "client",
        label: "Client",
        type: "select",
        collection: "clients"
      },
      { 
        id: "shipment_method",
        label: "Méthode d'envoi",
        type: "select",
        options: ["Container", "Colis express"]
      },
      { 
        id: "status",
        label: "Statut",
        type: "select",
        options: ["Confirmé", "Non confirmé"]
      },

      {
        id: "production_date",
        label: "Production",
        type: "date",
      },
      {
        id: "loading_date",
        label: "Chargement",
        type: "date",
      },
      {
        id: "arrival_date",
        label: "Arrivée",
        type: "date",
      },
      {
        id: "required",
        label: "Requis",
        type: "text",
      },
      {
        id: "comment",
        label: "Commentaire",
        type: "text",
        multiline: true
      },
      {
        id: "forwarder",
        label: "Transitaire",
        type: "text",
      },
      {
        id: "30_percents",
        label: "30 %",
        type: "checkbox",
      },
      {
        id: "70_percents",
        label: "70 %",
        type: "checkbox",
      },
      {
        id: "documents_sent",
        label: "Documents envoyés",
        type: "checkbox",
      },

    ],
    columns: [
      {
        field: "order_code",
        headerName: "Commande",
        width: 130,
      },
      {
        field: "client",
        headerName: "Client",
        width: 150,
        renderCell: (params) => {
          return (params.row && params.row.client ? params.row.client.name : "");
        },
      },
      {
        field: "30_percents",
        headerName: "30%",
        width: 30,
        renderCell: (params) => {
          return params.row['30_percents'] ? <DoneIcon color="success" /> : <CloseIcon color="error" />;
        },
      },
      {
        field: "70_percents",
        headerName: "70%",
        width: 30,
        renderCell: (params) => {
          return params.row['70_percents'] ? <DoneIcon color="success" /> : <CloseIcon color="error" />;
        },
      },
      {
        field: "production_date",
        headerName: "Production",
        width: 110,
        renderCell: (params) => {
          let date_str = "";
          if (params.row && params.row.production_date) {
            date_str = date_fr(params.row.production_date)
          }
          return date_str;
        },
      },
      {
        field: "loading_date",
        headerName: "Chargement",
        width: 110,
        renderCell: (params) => {
          let date_str = "";
          if (params.row && params.row.loading_date) {
            date_str = date_fr(params.row.loading_date)
          }
          return date_str;
        },
      },
      {
        field: "arrival_date",
        headerName: "Arrivée",
        width: 110,
        renderCell: (params) => {
          let date_str = "";
          if (params.row && params.row.arrival_date) {
            date_str = date_fr(params.row.arrival_date)
          }
          return date_str;
        },
      },
      {
        field: "documents_sent",
        headerName: "Doc",
        width: 30,
        renderCell: (params) => {
          return params.row.documents_sent ? <DoneIcon color="success" /> : <CloseIcon color="error" />;
        },
      },
      {
        field: "forwarder",
        headerName: "Transitaire",
        width: 130,
      },
      {
        field: "shipment_method",
        headerName: "Méthode d'envoi",
        width: 130,
      },
      {
        field: "comment",
        headerName: "Commentaire",
        flex:1,
        minWidth: 180,
      }
    ],
    labels: {
      listTitle: "Liste des commandes",
      editTitle: "Modifier la commande",
      addBtn: "Ajouter une commande"
    }


  }
}