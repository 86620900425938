import "./login.scss";
import { signInWithGooglePopup } from "../../firebase";
import { useContext, useState } from "react";
import {useNavigate} from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { Stack, Typography } from "@mui/material"; 


const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const {dispatch} = useContext(AuthContext);

  const logGoogleUser = async () => {
    signInWithGooglePopup()
      .then((userCredential) => {
        const user = userCredential.user;
        dispatch({type: "LOGIN", payload: user})
        setErrorMessage("");
        navigate("/");
      })
      .catch((error) => {
        setErrorMessage(error.message);
      })
  }

  return (
          <Stack className="login">
              <Typography variant="h2" component="h1" align="center" sx={{ mb: 2}} >e-planning</Typography>
              <button onClick={logGoogleUser}>Authentification avec Google</button>
              {errorMessage && <p><span>{errorMessage}</span></p>}
          </Stack>
      )
  }

export default Login;