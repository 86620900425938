import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState, useEffect } from "react";
import { collection, addDoc, serverTimestamp, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { Objects } from "../../formSource";
import { useNavigate } from "react-router-dom";
import { Input, InputLabel, FormControl, FormControlLabel, Checkbox, Select, MenuItem } from "@mui/material";

const New = ({ inputs, dataType }) => {
  const [data, setData] = useState({});
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();
  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setData({...data, [id]:value});
  }

  const handleCheckChange = (e, id) => {
    const value = e.target.checked;
    setData({...data, [id]:value});
  }

  const handleSelect = (e, id) => {
    const value = e.target.value;
    setData({...data, [id]:value});
  }

  const fetchClients = async() => {
    const unsub = onSnapshot(collection(db, "clients"), (snapShot) => {
      let list = [""];
      snapShot.docs.forEach(doc => {
        list.push(doc.data())
      });
      setClients(list);
    },
    (error) => { console.log(error); }
  );
  return () => unsub();
  }

  const handleAdd = async(e) => {
    e.preventDefault();
    try {
      const res = await addDoc(collection(db, dataType), {...data, time_stamp: serverTimestamp()});
      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{Objects[dataType].labels.addBtn}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form onSubmit={handleAdd}>
              {Objects[dataType].inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <FormControl fullWidth>
                  {input.type !== "select" && input.type !== "checkbox" && 
                    <>
                      <InputLabel shrink={true}>{input.label}</InputLabel>
                      <Input
                        label={input.label}
                        id={input.id}
                        type={input.type}
                        // variant="outlined"
                        placeholder={input.placeholder}
                        value={data[input.id] ?? ""}
                        onChange={handleInput}
                        multiline={input.multiline}
                        />
                    </>
                  }

                  {input.type === "checkbox" && 
                    <FormControlLabel
                      control={<Checkbox id={input.id} name={input.id} onChange={e => handleCheckChange(e, input.id)} />}
                      label={input.label}
                    />
                  }


                    {input.type === "select" &&
                    <>
                    <InputLabel>{input.label}</InputLabel>
                    <Select
                      id={input.id}
                      value={data[input.id] ?? ""}
                      label={input.label}
                      name={input.id}
                      onChange={e => handleSelect(e, input.id)}
                    >

                      {input.id === "client" ? clients.map((client, index) =>
                        <MenuItem value={client} key={index}>{client.name}</MenuItem>
                      )
                      :
                      input.options.map((option, index) => 
                      <MenuItem value={option} key={index}>{option}</MenuItem>)
                    }
                    </Select>
                    </>
                  }
                  </FormControl>
                </div>
              ))}
              <button type="submit">Enregistrer</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
