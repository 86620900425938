import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDt2slvDO9B_icrK1BA7AxpO8-hvFQyszE",
  authDomain: "e-planning-8ce72.firebaseapp.com",
  projectId: "e-planning-8ce72",
  storageBucket: "e-planning-8ce72.appspot.com",
  messagingSenderId: "914875826584",
  appId: "1:914875826584:web:58e3a04160babfb2776bed"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);


// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();
  
// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({   
    prompt : "select_account "
});
export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);