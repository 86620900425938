import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Objects } from "../../formSource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, deleteDoc, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const Datatable = (props) => {
  const { dataType } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    const unsub = onSnapshot(collection(db, dataType), (snapShot) => {
      let list = [];
      snapShot.docs.forEach(doc => {
        list.push({id: doc.id, ...doc.data()})
      });
      setData(list);
    },
    (error) => { console.log(error); }
  );
  return () => unsub();
  }, [dataType]);

  
  const handleDelete = async(id) => {
    try {
      await deleteDoc(doc(db, dataType, id))
      setData(data.filter((item) => item.id !== id));
    } catch(err) {
      console.log(err);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/${dataType}/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton"><EditIcon titleAccess="Modifier" /></div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              <DeleteIcon titleAccess="Supprimer" />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">
        {Objects[dataType].labels.listTitle}
        <Link to={`/${dataType}/new`} className="link">
        {Objects[dataType].labels.addBtn}
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={Objects[dataType].columns.concat(actionColumn)}
        pageSize={10}
        // rowsPerPageOptions={[9]}
        disableRowSelectionOnClick={true}
        getRowClassName={(params) => {
          let style = 'test';
          const today = new Date().toJSON().slice(0, 10);
          console.log(today)
          console.log(params.row.order_code)
          console.log(params.row.production_date)
          if (params.row.production_date < today) style = 'production';
          // if (params.row.production_date <= today) style = 'produced';
          if (params.row.loading_date <= today) style = 'loaded';
          if (params.row.arrival_date <= today) style = 'arrived';
          return style
        }}
        // checkboxSelection
      />
    </div>
  );
};

export default Datatable;
