import "./sidebar.scss";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Link, useNavigate } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";


const DarkModeMenu = () => {
  const { dispatch } = useContext(DarkModeContext);

  return(
    <div className="bottom">
    <div
      className="colorOption"
      onClick={() => dispatch({ type: "LIGHT" })}
    ></div>
    <div
      className="colorOption"
      onClick={() => dispatch({ type: "DARK" })}
    ></div>
  </div>
  )

}


const Sidebar = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);

  const sessionSignOut = async () => {
    try {
      signOut(auth);
      dispatch({type: "LOGOUT"})
      navigate("/");
    } catch(error) {
      console.log(error)
    }
  }

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">e-planning</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <Link to="/orders" style={{ textDecoration: "none" }}>
            <li>
            <LocalShippingIcon className="icon" />
              <span>Commandes</span>
            </li>
          </Link>
          <Link to="/clients" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Clients</span>
            </li>
          </Link>
          <li onClick={sessionSignOut}>
            <ExitToAppIcon className="icon" />
            <span>Déconnexion</span>
          </li>
          <DarkModeMenu />
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
