import Login from "./pages/login/Login";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/AuthContext";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/fr';

function App() {
  const { darkMode } = useContext(DarkModeContext);

  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({children}) => {
    return currentUser ? (children) : <Navigate to="/login" />
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="login" element={<Login />} />
            <Route index element={<RequireAuth><Navigate to="/orders" /></RequireAuth>} />
            <Route path="clients">
              <Route index element={<RequireAuth><List dataType="clients" /></RequireAuth>} />
              <Route path=":docId" element={<RequireAuth><Single dataType="clients" /></RequireAuth>} />
              <Route
                path="new"
                element={<RequireAuth><New dataType="clients" /></RequireAuth>}
              />
            </Route>
            <Route path="orders">
              <Route index element={<RequireAuth><List dataType="orders" /></RequireAuth>} />
              <Route path=":docId" element={<RequireAuth><Single dataType="orders" /></RequireAuth>} />
              <Route
                path="new"
                element={<RequireAuth><New dataType="orders" /></RequireAuth>}
              />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
    </LocalizationProvider>
  );
}

export default App;
